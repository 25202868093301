export enum BookingType {
  VEGYES = 'VEGYES',

  ATVEZETES = 'ATVEZETES',

  KISREND_VE = 'KISREND_VE',

  KISREND_SZ = 'KISREND_SZ',

  ATERTEKELES = 'ATERTEKELES',

  UGYNOKI_ELSZAMOLAS = 'UGYNOKI_ELSZAMOLAS'
}