import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'application',
        data: { pageTitle: 'magusApp.application.home.title' },
        loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
      },
      {
        path: 'piece-of-news',
        data: { pageTitle: 'magusApp.pieceOfNews.home.title' },
        loadChildren: () => import('./piece-of-news/piece-of-news.module').then(m => m.PieceOfNewsModule),
      },
      {
        path: 'parameter',
        data: { pageTitle: 'magusApp.parameter.home.title' },
        loadChildren: () => import('./parameter/parameter.module').then(m => m.ParameterModule),
      },
      {
        path: 'special-day',
        data: { pageTitle: 'magusApp.specialDay.home.title' },
        loadChildren: () => import('./special-day/special-day.module').then(m => m.SpecialDayModule),
      },
      {
        path: 'common-voc-categ',
        data: { pageTitle: 'magusApp.commonVocCateg.home.title' },
        loadChildren: () => import('./common-voc-categ/common-voc-categ.module').then(m => m.CommonVocCategModule),
      },
      {
        path: 'common-vocabulary',
        data: { pageTitle: 'magusApp.commonVocabulary.home.title' },
        loadChildren: () => import('./common-vocabulary/common-vocabulary.module').then(m => m.CommonVocabularyModule),
      },
      {
        path: 'payment-method',
        data: { pageTitle: 'magusApp.paymentMethod.home.title' },
        loadChildren: () => import('./payment-method/payment-method.module').then(m => m.PaymentMethodModule),
      },
      {
        path: 'country',
        data: { pageTitle: 'magusApp.country.home.title' },
        loadChildren: () => import('./country/country.module').then(m => m.CountryModule),
      },
      {
        path: 'currency',
        data: { pageTitle: 'magusApp.currency.home.title' },
        loadChildren: () => import('./currency/currency.module').then(m => m.CurrencyModule),
      },
      {
        path: 'teaor',
        data: { pageTitle: 'magusApp.teaor.home.title' },
        loadChildren: () => import('./teaor/teaor.module').then(m => m.TeaorModule),
      },
      {
        path: 'task-view',
        data: { pageTitle: 'magusApp.taskView.home.title' },
        loadChildren: () => import('./task-view/task-view.module').then(m => m.TaskViewModule),
      },
      {
        path: 'email-template',
        data: { pageTitle: 'magusApp.emailTemplate.home.title' },
        loadChildren: () => import('./email-template/email-template.module').then(m => m.EmailTemplateModule),
      },
      {
        path: 'price-type',
        data: { pageTitle: 'magusApp.priceType.home.title' },
        loadChildren: () => import('./price-type/price-type.module').then(m => m.PriceTypeModule),
      },
      {
        path: 'partner-group',
        data: { pageTitle: 'magusApp.partnerGroup.home.title' },
        loadChildren: () => import('./partner-group/partner-group.module').then(m => m.PartnerGroupModule),
      },
      {
        path: 'partner-category',
        data: { pageTitle: 'magusApp.partnerCategory.home.title' },
        loadChildren: () => import('./partner-category/partner-category.module').then(m => m.PartnerCategoryModule),
      },
      {
        path: 'partner',
        data: { pageTitle: 'magusApp.partner.home.title' },
        loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule),
      },
      {
        path: 'mag-json-param',
        data: { pageTitle: 'magusApp.magJsonParam.home.title' },
        loadChildren: () => import('./mag-json-param/mag-json-param.module').then(m => m.MagJsonParamModule),
      },
      {
        path: 'file-element',
        data: { pageTitle: 'magusApp.fileElement.home.title' },
        loadChildren: () => import('./file-element/file-element.module').then(m => m.FileElementModule),
      },
      {
        path: 'file-definition',
        data: { pageTitle: 'magusApp.fileDefinition.home.title' },
        loadChildren: () => import('./file-definition/file-definition.module').then(m => m.FileDefinitionModule),
      },
      {
        path: 'doc-category',
        data: { pageTitle: 'magusApp.docCategory.home.title' },
        loadChildren: () => import('./doc-category/doc-category.module').then(m => m.DocCategoryModule),
      },
      {
        path: 'doc',
        data: { pageTitle: 'magusApp.doc.home.title' },
        loadChildren: () => import('./doc/doc.module').then(m => m.DocModule),
      },
      {
        path: 'doc-file',
        data: { pageTitle: 'magusApp.docFile.home.title' },
        loadChildren: () => import('./doc-file/doc-file.module').then(m => m.DocFileModule),
      },
      {
        path: 'access-function',
        data: { pageTitle: 'magusApp.accessFunction.home.title' },
        loadChildren: () => import('./access-function/access-function.module').then(m => m.AccessFunctionModule),
      },
      {
        path: 'access-group',
        data: { pageTitle: 'magusApp.accessGroup.home.title' },
        loadChildren: () => import('./access-group/access-group.module').then(m => m.AccessGroupModule),
      },
      {
        path: 'access-right',
        data: { pageTitle: 'magusApp.accessRight.home.title' },
        loadChildren: () => import('./access-right/access-right.module').then(m => m.AccessRightModule),
      },
      {
        path: 'gl-category',
        data: { pageTitle: 'magusApp.glCategory.home.title' },
        loadChildren: () => import('./gl-category/gl-category.module').then(m => m.GlCategoryModule),
      },
      {
        path: 'gl-journal-type',
        data: { pageTitle: 'magusApp.glJournalType.home.title' },
        loadChildren: () => import('./gl-journal-type/gl-journal-type.module').then(m => m.GlJournalTypeModule),
      },
      {
        path: 'gl-cost-center',
        data: { pageTitle: 'magusApp.glCostCenter.home.title' },
        loadChildren: () => import('./gl-cost-center/gl-cost-center.module').then(m => m.GlCostCenterModule),
      },
      {
        path: 'gl-cost-category',
        data: { pageTitle: 'magusApp.glCostCategory.home.title' },
        loadChildren: () => import('./gl-cost-category/gl-cost-category.module').then(m => m.GlCostCategoryModule),
      },
      {
        path: 'gl-job-number',
        data: { pageTitle: 'magusApp.glJobNumber.home.title' },
        loadChildren: () => import('./gl-job-number/gl-job-number.module').then(m => m.GlJobNumberModule),
      },
      {
        path: 'gl-post-error',
        data: { pageTitle: 'magusApp.glPostError.home.title' },
        loadChildren: () => import('./gl-post-error/gl-post-error.module').then(m => m.GlPostErrorModule),
      },
      {
        path: 'gl-statement',
        data: { pageTitle: 'magusApp.glStatement.home.title' },
        loadChildren: () => import('./gl-statement/gl-statement.module').then(m => m.GlStatementModule),
      },
      {
        path: 'gl-journal-view',
        data: { pageTitle: 'magusApp.glJournal.home.title' },
        loadChildren: () => import('./gl-journal-view/gl-journal-view.module').then(m => m.GlJournalViewModule),
      },
      {
        path: 'language',
        data: { pageTitle: 'magusApp.language.home.title' },
        loadChildren: () => import('./language/language.module').then(m => m.LanguageModule),
      },
      {
        path: 'measuring-unit',
        data: { pageTitle: 'magusApp.measuringUnit.home.title' },
        loadChildren: () => import('./measuring-unit/measuring-unit.module').then(m => m.MeasuringUnitModule),
      },
      {
        path: 'combined-nomenclature',
        data: { pageTitle: 'magusApp.combinedNomenclature.home.title' },
        loadChildren: () => import('./combined-nomenclature/combined-nomenclature.module').then(m => m.CombinedNomenclatureModule),
      },
      {
        path: 'teszor',
        data: { pageTitle: 'magusApp.teszor.home.title' },
        loadChildren: () => import('./teszor/teszor.module').then(m => m.TeszorModule),
      },
      {
        path: 'exchange-rate',
        data: { pageTitle: 'magusApp.exchangeRate.home.title' },
        loadChildren: () => import('./exchange-rate/exchange-rate.module').then(m => m.ExchangeRateModule),
      },
      {
        path: 'product-group',
        data: { pageTitle: 'magusApp.productGroup.home.title' },
        loadChildren: () => import('./product-group/product-group.module').then(m => m.ProductGroupModule),
      },
      {
        path: 'product',
        data: { pageTitle: 'magusApp.product.home.title' },
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'vat-rate',
        data: { pageTitle: 'magusApp.vatRate.home.title' },
        loadChildren: () => import('./vat-rate/vat-rate.module').then(m => m.VatRateModule),
      },
      /* jhipster-needle-add-entity-route - JHipster will add entity modules routes here */
      {
        path: 'invoice-out',
        data: { pageTitle: 'magusApp.invoiceOut.home.title' },
        loadChildren: () => import('./invoice-out/invoice-out.module').then(m => m.InvoiceOutModule),
      },
      {
        path: 'invoice-nav-in',
        data: { pageTitle: 'magusApp.invoiceNavIn.home.title' },
        loadChildren: () => import('./invoice-nav-in/invoice-nav-in.module').then(m => m.InvoiceNavInModule),
      },
      {
        path: 'invoice-in',
        data: { pageTitle: 'magusApp.invoiceIn.home.title' },
        loadChildren: () => import('./invoice-in/invoice-in.module').then(m => m.InvoiceInModule),
      },
      {
        path: 'gl-booking',
        data: { pageTitle: 'magusApp.glBooking.home.title' },
        loadChildren: () => import('./gl-booking/gl-booking.module').then(m => m.GlBookingModule),
      },
      {
        path: 'vat-subledger-view',
        data: { pageTitle: 'magusApp.vatSubledgerView.home.title' },
        loadChildren: () => import('./vat-subledger-view/vat-subledger-view.module').then(m => m.VatSubledgerViewModule),
      },
      {
        path: 'vat-a-60-view',
        data: { pageTitle: 'magusApp.vatA60View.home.title' },
        loadChildren: () => import('./vat-a-60-view/vat-a-60-view.module').then(m => m.VatA60ViewModule),
      },
      {
        path: 'nav-form-row',
        data: { pageTitle: 'magusApp.navFormRow.home.title' },
        loadChildren: () => import('./nav-form-row/nav-form-row.module').then(m => m.NavFormRowModule),
      },
      {
        path: 'vat-type',
        data: { pageTitle: 'magusApp.vatType.home.title' },
        loadChildren: () => import('./vat-type/vat-type.module').then(m => m.VatTypeModule),
      },
      {
        path: 'invoice-out-line-product-fee',
        data: { pageTitle: 'magusApp.invoiceOutLineProductFee.home.title' },
        loadChildren: () =>
          import('./invoice-out-line-product-fee/invoice-out-line-product-fee.module').then(m => m.InvoiceOutLineProductFeeModule),
      },
      {
        path: 'invoice-in-line-product-fee',
        data: { pageTitle: 'magusApp.invoiceInLineProductFee.home.title' },
        loadChildren: () =>
          import('./invoice-in-line-product-fee/invoice-in-line-product-fee.module').then(m => m.InvoiceInLineProductFeeModule),
      },
      {
        path: 'bank-statement',
        data: { pageTitle: 'magusApp.bankStatement.home.title' },
        loadChildren: () => import('./bank-statement/bank-statement.module').then(m => m.BankStatementModule),
      },
      {
        path: 'vat-rate-exemption',
        data: { pageTitle: 'magusApp.vatRateExemption.home.title' },
        loadChildren: () => import('./vat-rate-exemption/vat-rate-exemption.module').then(m => m.VatRateExemptionModule),
      },
      {
        path: 'cash-transaction',
        data: { pageTitle: 'magusApp.cashTransaction.home.title' },
        loadChildren: () => import('./cash-transaction/cash-transaction.module').then(m => m.CashTransactionModule),
      },
      {
        path: 'bank-account',
        data: { pageTitle: 'magusApp.bankAccount.home.title' },
        loadChildren: () => import('./bank-account/bank-account.module').then(m => m.BankAccountModule),
      },
      {
        path: 'bank-transfer-order',
        data: { pageTitle: 'magusApp.bankTransferOrder.home.title' },
        loadChildren: () => import('./bank-transfer-order/bank-transfer-order.module').then(m => m.BankTransferOrderModule),
      },
      {
        path: 'bank-statement-line-view',
        data: { pageTitle: 'magusApp.bankStatementLineView.home.title' },
        loadChildren: () => import('./bank-statement-line-view/bank-statement-line-view.module').then(m => m.BankStatementLineViewModule),
      },
      {
        path: 'cash',
        data: { pageTitle: 'magusApp.cash.home.title' },
        loadChildren: () => import('./cash/cash.module').then(m => m.CashModule),
      },
      {
        path: 'cash-day',
        data: { pageTitle: 'magusApp.cashDay.home.title' },
        loadChildren: () => import('./cash-day/cash-day.module').then(m => m.CashDayModule),
      },
      {
        path: 'cash-transaction-line-view',
        data: { pageTitle: 'magusApp.cashTransactionLineView.home.title' },
        loadChildren: () => import('./cash-transaction-line-view/cash-transaction-line-view.module').then(m => m.CashTransactionLineViewModule),
      },
      {
        path: 'document-comment',
        data: { pageTitle: 'magusApp.documentComment.home.title' },
        loadChildren: () => import('./document-comment/document-comment.module').then(m => m.DocumentCommentModule),
      },
      {
        path: 'store',
        data: { pageTitle: 'magusApp.store.home.title' },
        loadChildren: () => import('./store/store.module').then(m => m.StoreModule),
      },
      {
        path: 'stock-movement-type',
        data: { pageTitle: 'magusApp.stockMovementType.home.title' },
        loadChildren: () => import('./stock-movement-type/stock-movement-type.module').then(m => m.StockMovementTypeModule),
      },
      {
        path: 'route',
        data: { pageTitle: 'magusApp.route.home.title' },
        loadChildren: () => import('./route/route.module').then(m => m.RouteModule),
      },
      {
        path: 'order',
        data: { pageTitle: 'magusApp.order.home.title' },
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
      },
      {
        path: 'stock-movement',
        data: { pageTitle: 'magusApp.stockMovement.home.title' },
        loadChildren: () => import('./stock-movement/stock-movement.module').then(m => m.StockMovementModule),
      },
      {
        path: 'stock-out-movement-line',
        data: { pageTitle: 'magusApp.stockOutMovementLine.home.title' },
        loadChildren: () => import('./stock-out-movement-line/stock-out-movement-line.module').then(m => m.StockOutMovementLineModule),
      },
      {
        path: 'stock-movement-line-view',
        data: { pageTitle: 'magusApp.stockMovementLineView.home.title' },
        loadChildren: () => import('./stock-movement-line-view/stock-movement-line-view.module').then(m => m.StockMovementLineViewModule),
      },
      {
        path: 'stock-transfer',
        data: { pageTitle: 'magusApp.stockTransfer.home.title' },
        loadChildren: () => import('./stock-transfer/stock-transfer.module').then(m => m.StockTransferModule),
      },
      {
        path: 'stock-take',
        data: { pageTitle: 'magusApp.stockTake.home.title' },
        loadChildren: () => import('./stock-take/stock-take.module').then(m => m.StockTakeModule),
      },
      {
        path: 'stock',
        data: { pageTitle: 'magusApp.stock.home.title' },
        loadChildren: () => import('./stock/stock.module').then(m => m.StockModule),
      },
      {
        path: 'stock-view',
        data: { pageTitle: 'magusApp.stockView.home.title' },
        loadChildren: () => import('./stock-view/stock-view.module').then(m => m.StockViewModule),
      },
      {
        path: 'stock-by-product-view',
        data: { pageTitle: 'magusApp.stockByProductView.home.title' },
        loadChildren: () => import('./stock-by-product-view/stock-by-product-view.module').then(m => m.StockByProductViewModule),
      },
      {
        path: 'product-price',
        data: { pageTitle: 'magusApp.productPrice.home.title' },
        loadChildren: () => import('./product-price/product-price.module').then(m => m.ProductPriceModule),
      },
      {
        path: 'product-discount',
        data: { pageTitle: 'magusApp.productDiscount.home.title' },
        loadChildren: () => import('./product-discount/product-discount.module').then(m => m.ProductDiscountModule),
      },
      {
        path: 'product-additional-charge',
        data: { pageTitle: 'magusApp.productAdditionalCharge.home.title' },
        loadChildren: () =>
          import('./product-additional-charge/product-additional-charge.module').then(m => m.ProductAdditionalChargeModule),
      },
      {
        path: 'asset-inventory-area',
        data: { pageTitle: 'magusApp.assetInventoryArea.home.title' },
        loadChildren: () => import('./asset-inventory-area/asset-inventory-area.module').then(m => m.AssetInventoryAreaModule),
      },
      {
        path: 'asset',
        data: { pageTitle: 'magusApp.asset.home.title' },
        loadChildren: () => import('./asset/asset.module').then(m => m.AssetModule),
      },
      // {
      //   path: 'asset-depr-param',
      //   data: { pageTitle: 'magusApp.assetDeprParam.home.title' },
      //   loadChildren: () => import('./asset-depr-param/asset-depr-param.module').then(m => m.AssetDeprParamModule),
      // },
      {
        path: 'asset-event',
        data: { pageTitle: 'magusApp.assetEvent.home.title' },
        loadChildren: () => import('./asset-event/asset-event.module').then(m => m.AssetEventModule),
      },
      {
        path: 'gl-asset-assignment-rule',
        data: { pageTitle: 'magusApp.glAssetAssignmentRule.home.title' },
        loadChildren: () => import('./gl-asset-assignment-rule/gl-asset-assignment-rule.module').then(m => m.GlAssetAssignmentRuleModule),
      },
      {
        path: 'gl-report',
        data: { pageTitle: 'magusApp.glReport.home.title' },
        loadChildren: () => import('./gl-report/gl-report.module').then(m => m.GlReportModule),
      },
      {
        path: 'gl-report-template',
        data: { pageTitle: 'magusApp.glReportTemplate.home.title' },
        loadChildren: () => import('./gl-report-template/gl-report-template.module').then(m => m.GlReportTemplateModule),
      },
      {
        path: 'asset-event-line-view',
        data: { pageTitle: 'magusApp.assetEventLineView.home.title' },
        loadChildren: () => import('./asset-event-line-view/asset-event-line-view.module').then(m => m.AssetEventLineViewModule),
      },
      {
        path: 'aging-category',
        data: { pageTitle: 'magusApp.agingCategory.home.title' },
        loadChildren: () => import('./aging-category/aging-category.module').then(m => m.AgingCategoryModule),
      },
      {
        path: 'balance-statement',
        data: { pageTitle: 'magusApp.balanceStatement.home.title' },
        loadChildren: () => import('./balance-statement/balance-statement.module').then(m => m.BalanceStatementModule),
      },
      {
        path: 'fox-szlak-upload',
        data: { pageTitle: 'magusApp.foxSzlakUpload.home.title' },
        loadChildren: () => import('./fox-szlak-upload/fox-szlak-upload.module').then(m => m.FoxSzlakUploadModule),
      },
      {
        path: 'nav-tax-code',
        data: { pageTitle: 'magusApp.navTaxCode.home.title' },
        loadChildren: () => import('./nav-tax-code/nav-tax-code.module').then(m => m.NavTaxCodeModule),
      },
      {
        path: 'vehicle',
        data: { pageTitle: 'magusApp.vehicle.home.title' },
        loadChildren: () => import('./vehicle/vehicle.module').then(m => m.VehicleModule),
      },
      {
        path: 'mileage-record',
        data: { pageTitle: 'magusApp.mileageRecord.home.title' },
        loadChildren: () => import('./mileage-record/mileage-record.module').then(m => m.MileageRecordModule),
      },
      {
        path: 'contract',
        data: { pageTitle: 'magusApp.contract.home.title' },
        loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
      },
      {
        path: 'contract-account',
        data: { pageTitle: 'magusApp.contractAccount.home.title' },
        loadChildren: () => import('./contract-account/contract-account.module').then(m => m.ContractAccountModule),
      },
      {
        path: 'invoice-out-cash-payment',
        data: { pageTitle: 'magusApp.invoiceOutCashPayment.home.title' },
        loadChildren: () => import('./invoice-out-cash-payment/invoice-out-cash-payment.module').then(m => m.InvoiceOutCashPaymentModule),
      },
      {
        path: 'pending-settlement',
        data: { pageTitle: 'magusApp.pendingSettlement.home.title' },
        loadChildren: () => import('./pending-settlement/pending-settlement.module').then(m => m.PendingSettlementModule),
      },
      {
        path: 'intrastat-report',
        data: { pageTitle: 'magusApp.intrastatReport.home.title' },
        loadChildren: () => import('./intrastat-report/intrastat-report.module').then(m => m.IntrastatReportModule),
      },
      {
        path: 'production',
        data: { pageTitle: 'magusApp.production.home.title' },
        loadChildren: () => import('./production/production.module').then(m => m.ProductionModule),
      },
      // {
      //   path: 'import-invoice-out',
      //   data: { pageTitle: 'magusApp.importInvoiceOut.home.title' },
      //   loadChildren: () => import('./import-invoice-out/import-invoice-out.module').then(m => m.AgingCategoryModule),
      // },
    ]),
  ],
})
export class EntityRoutingModule {}
